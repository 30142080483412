<template>
  <v-app>
  <!-- color="indigo darken-2" -->
  <v-app-bar
    collapse
    collapse-on-scroll
    color="light-green"
    dark
    dense
    >
      <div id="nav">
        <router-link to="/"><i>Beta</i></router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/how">Quick Start</router-link>
      </div>
      <!-- auto space between Home/About and HelloWorld --> 
      <v-spacer></v-spacer>
      <router-view/>
      <!-- auto space between HelloWorld and Log in -->
      <v-spacer></v-spacer>
      <!-- drop down opeions for language support v-menu etc. -->
  </v-app-bar>
  <GmapMap
      :center="center"
      :zoom="12.25"
      map-type-id="roadmap"
      style="width: 100%; height: 700px"
      @click="updateCenter"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"        
      />
      <GmapCircle
      :center="center"
      :radius="5000"
      :options="{fillColor:'green',fillOpacity:0.1}"
      />
    </GmapMap>
    <p>Center: <b>Latitude</b> {{ center.lat.toFixed(2) }} , <b>Longitude</b> {{ center.lng.toFixed(2) }} </p>
    <p></p>

    <div id="msg" style="color:Red;">
      {{ message }}
    </div>

    <p> </p>
    <!-- only when valid data rows > 1 header row -->
    <div id="sum" style="color:Green;" v-if = 'markerschart.length > 1'>
      {{ total }}
    </div>
    <p> </p>
    <div id="appTable" v-if = 'markersdata.length > 1'>
      <GChart
        :settings="{ packages: ['table'] }"
        type="Table"
        :data="markersdata"                    
        :options="tableOptions"
        ref="gChart"
      />
      <p>Source: <a href="https://www.flaringmonitor.org/"><span style='color:green'>Flaring Monitor</span></a></p>    
    </div>
    <p> </p>
    <!-- only when valid data rows > 1 header row -->
    <div id="appChart" v-if = 'markerschart.length > 1'>
      <GChart
        type="ColumnChart"
        :data="markerschart"
        :options="chartOptions"
      />    
    </div>
    <div id="appSankey" v-if = 'flared_sankey.length > 3 && markerschart.length > 2' >
        <p><b><u>Retrospective</u> - How are <i>cummulative</i> flared volumes attributable to <i>different</i> operators?</b></p>
        <GChart
        :settings= "{ packages: ['sankey'] }"
          type= "Sankey"
          :data= "flared_sankey"
          :options= "sankey_Options"
        />
    </div>
  <v-footer
      color="light-green darken-2"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          x-small
          color="white"
          text
          disabled
        >
        </v-btn>
        <v-col
          class="light-green darken-2 text-center white--text"
        >
          <small> Copyright &copy; {{  new Date().getFullYear() }} <strong> Shaleforce LLC</strong>. All rights reserved. </small>
        </v-col>
      </v-row>
    </v-footer>
</v-app>
</template>

<script>

import axios from 'axios';
import { GChart } from "vue-google-charts";

// return true if in range, otherwise false
function inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
}
// determine number of wells per formation
var occurrence = function (array) {
    "use strict";
    var result = {};
    if (array instanceof Array) {
        array.forEach(function (v, i) {
            if (!result[v]) {
                result[v] = [i];
            } else {
                result[v].push(i);
            }
        });
        Object.keys(result).forEach(function (v) {
            result[v] = result[v].length;
        });
    }
    return result;
};

export default {
  name: 'App',
  components: {
      GChart
    },
  data() {
    return {
      // default to Midland TX
      center: { lat: 32.00002, lng: -102.07446 },
      message: '',
      markers: [],
      markersdata: [],
      markerschart: [],
      markersopr: [],
      flared_sankey: [],
      places: [],
      currentPlace: null,
      tableOptions: {
        chart: {
          title: "Flaring Wells Summary",
          subtitle: "State, Operators, Assets and Flared Volumes"
        },
        height: 600,
        width:  900,
      },
      /* tableEvents: {
        select: () => {          
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();          
          const onSelectionMeaasge = selection.length !== 0 ? 'row was selected' : 'row was diselected'
          alert(onSelectionMeaasge);
        }
      }, */

      chartOptions: {
        title: 'Which operators are responsible for Flaring observed in this area?',
        hAxis: {
          title: 'Operator',
          titleTextStyle: {
          color: '#1b9e77'
          }
        },
        vAxis:{
          title: 'Number of Reported Cluster-Months', titleTextStyle: {color: '#1b9e77'}
        },
        legend: {
          position: 'none',
          alignment: 'start'
        },
        height: 600,
        colors: ['#FF0000'],
        minValue: 0,
        /* isStacked: ["True"], *?
        /* bar: { gap: 0 }  separataion between bars */
        /* histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 5,
          minValue: 0,
          maxValue: 300,
          hideBucketItems: 'false'
        } */
      },
      sankey_Options: {
        chart:{
          title: "Flared Volume Distribution",
        },
        height: 600,
        sankey: {
          node: {
            width: 4,
            nodePadding: 50,
            label:{
              fontName: 'Avenir',
              color: '#505050',
              bold: true,
              fontSize: 20,
            }
          },
          link: {
            color:{
              fillOpacity: 1,
            },
           colorMode: 'gradient',
          },
        }
      }
    }
  },
  // access local data
  /* mounted () {
    axios.get("./data/welldata.json")
    .then(response => (this.welldata = response.data)) 
  }, */
  // Learn more about this component at https://www.npmjs.com/package/vue2-google-maps
  methods: {
    async updateCenter(event) {
      // Remove existing markers
      this.markers = [];
      this.markersdata = [];
      this.markerschart = [];
      this.markersopr = [];
      this.flared_sankey = [];
      this.message = '';
      this.total = '';

      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      // min latitude set at the southern tip of Florida
      if (!(inRange(this.center.lat, 25.12, 49.38)
        && inRange(this.center.lng, -124.77, -66.95))) {
          this.message = 'Location falls outside the US Lower 48! Please try again.'
        } else { 

        try {
          "use strict";
          const results = await axios({
            method: 'post',
            url: 'https://pffa3nhs2h.execute-api.us-east-1.amazonaws.com/prod/',
            data: {
              lat: this.center.lat,
              lng: this.center.lng
            }
          })
          const markerlabel = [
            // { type: "number", label: "Cluster ID", id: "cid" },
            { type: "string", label: "State", id: "state" },
            { type: "string", label: "Operator", id: "operator" },
            { type: "number", label: "Cluster ID", id: "cid" },
            { type: "number", label: "Cluster Size (Wells)", id: "flare_wells" },
            { type: "string", label: "Date", id: "month" },
            { type: "number", label: "Flared Volume (Mcf/mo)", id: "flare_mcf" },
            { type: "number", label: "Flared Volume (MTon/mo)", id: "flare_ton" }
            ];
            // console.log(markerdata);
            this.markersdata.push(markerlabel);
          
            // this.markerschart.push(['Content', 'Count', { role: 'style' }]);
            this.markerschart.push(['Operator', 'Count']);
            this.flared_sankey.push(['State', 'Operator', 'Flared Volume (MTon/mo)']);

          results.data.map((location) => {
            const point = JSON.parse(location.geoJson.S)
            const marker = {
              lat: point.coordinates[1],
              lng: point.coordinates[0],
              state: location.state.S,
              operator: location.operator.S,
              cid: location.cid.N,
              flare_wells: location.flare_wells.N,
              month: location.month.S,
              flare_mcf: location.flare_mcf.N,
              flare_ton: location.flare_ton.N
            }
            this.markers.push({ position: marker });

            /* const markerdata = {
              // location.cid.N,
              state: location.state.S,
              operator: location.operator.S,
              flare_wells: location.flare_wells.N,
              flare_mcf: location.flare_mcf.N,
              flare_ton: location.flare_ton.N
          } */
            // console.log(markerdata);
            this.markersdata.push([marker.state, marker.operator, parseInt(marker.cid), parseInt(marker.flare_wells), marker.month,
              parseInt(marker.flare_mcf), parseFloat(marker.flare_ton)]);
            if (marker.flare_ton > 0) this.flared_sankey.push([marker.state, marker.operator, parseFloat(marker.flare_ton)]);

            // label only does not work for histogram
            this.markersopr.push(marker.operator);

          })
          // if no markers send message
          // console.log(this.flared_sankey.length);
          if (this.markers.length == 0) {
            this.message = 'No flaring clusters around this location. Try another spot.'
          } else {
            this.total = 'Way to go. Flaring reported a total of ' + this.markers.length +  ' cluster-months.'
          // extract number of occurrence
          // console.log(occurrence(this.markersopr));
            var entries = Object.entries(occurrence(this.markersopr));
            entries.sort();
            // console.log(entries);
            for (var i = 0; i < entries.length; i++) {
              this.markerschart.push(entries[i]);
            }
          }
        }
        catch (err) {
        // eslint-disable-next-line
        console.err('Error: ', err)
        }
      }
    }
  }  
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
