<template>
  <div class="home">
    <h3>Geosearch Flaring</h3>
    <h4>Reimagine Energy | Reuse Flares</h4>
  </div>
</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home'
}
</script>